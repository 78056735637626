import { Box, Dialog, Modal, Typography, makeStyles, DialogTitle, DialogContent, TextField, DialogActions, Button, ListItem } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api"
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        overflowY: 'unset',
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
}));

const CadastroTypebot = ({open, onClose}) => {

    const classes = useStyles()
    const [typebot, setTypebot] = useState({ nome: '', url: '', startGroupId: '', token: '', startChat: '' })

    const handleChangeInput = e => {
        setTypebot({ ...typebot, [e.target.name]: e.target.value });
    };

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = e => {

        api.post("/CadTypebot", typebot)
    }

    return (
        <div className={classes.root}>
            <Dialog
            open={open}
            onClose={onClose}
            >
                <DialogTitle>
                    Cadastrar Typebot
                </DialogTitle>
                    <DialogContent dividers>
                    <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nome"
                            label="nome"
                            name="nome"
                            value={typebot.nome}
                            onChange={handleChangeInput}
                            autoComplete=""
                            autoFocus
                            className={classes.textField}
                        />
                      <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="url"
                            label="Digite a url..."
                            name="url"
                            value={typebot.url}
                            onChange={handleChangeInput}
                            autoComplete="https://"
                            autoFocus
                            className={classes.textField}
                        ></TextField>
                    <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="startGroupId"
                            label="startGroupId"
                            name="startGroupId"
                            value={typebot.startGroupId}
                            onChange={handleChangeInput}
                            autoComplete=""
                            autoFocus
                            className={classes.textField}
                        />
                    <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="startChat"
                            label="startChat"
                            name="startChat"
                            value={typebot.startChat}
                            onChange={handleChangeInput}
                            autoComplete=""
                            autoFocus
                            className={classes.textField}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="token"
                            label="token"
                            name="token"
                            value={typebot.token}
                            onChange={handleChangeInput}
                            autoComplete=""
                            autoFocus
                            className={classes.textField}
                        />                          
                        <DialogActions>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Cadastrar Typebot
                            </Button>
                        </DialogActions>                        
                    </DialogContent>
            </Dialog>
        </div>
    )

}
export default CadastroTypebot